<template>
  <div class="gestion-appel-paiement-obligee">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date d'ébut</span>
          </div>
          <!-- :clearable="false"
            :editable="false" -->
          <date-picker
            value-type="format"
            type="date"
            required
            :value="date_debut"
            @input="handleDateTimePicker($event, 'date_debut')"
            :disabled-date="disabledStartDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Date fin</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            :value="date_fin"
            @input="handleDateTimePicker($event, 'date_fin')"
            :clear-button="true"
            :disabled-date="disabledEndDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style w-63-px">
            <span class="title-tabel">Organsime</span>
          </div>
          <multiselect
            v-model="filterOrganisme"
            :options="getListOrganismeObligee"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner organisme"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getLoadingDataFilterObligeeThProjects"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style w-126-px">
            <span class="title-tabel">Filiale Installateur</span>
          </div>
          <multiselect
            v-model="filterFiliale"
            :options="getListInstallateurbligee"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner filiale"
            :loading="getLoadingDataFilterObligeeThProjects"
            @input="handleFilter"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Lot</span>
          </div>
          <multiselect
            v-model="filterLot"
            :options="getListLotsOligee"
            :multiple="true"
            class="customSelectMultipleFilter customSelectMultipleFilterLot"
            :showLabels="false"
            placeholder="sélectionner lot"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getLoadingDataFilterObligeeThProjects"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div v-if="getChargementAppelPaiementThObligee" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2">
          <div v-if="getErrorAppeliPaiementThObligee" class="error">
            <ul
              v-if="Array.isArray(getErrorAppeliPaiementThObligee)"
              class="mb-0"
            >
              <li
                v-for="(e, index) in getErrorAppeliPaiementThObligee"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppeliPaiementThObligee }}</div>
          </div>
        </div>

        <div class="error-message ml-2">
          <div
            v-if="getErrorAppeliPaiementUpdateThObligee"
            class="error_oblige_update"
          >
            <b-alert show dismissible fade variant="danger">
              {{ getErrorAppeliPaiementUpdateThObligee }}
            </b-alert>
          </div>
        </div>

        <div class="box-end-position">
          <b-button
            size="sm"
            variant="danger"
            class="button-export-style  house-danger"
            v-if="missingOrganismeInObligeeFile.length"
            v-b-modal.missingOrganisme
          >
            <!-- <span style="">{{ missingOrganismeInObligeeFile.length }}</span> -->
            <font-awesome-icon icon="house-user" />
          </b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2"
            @click="refreshAooelPaiementObligee"
            v-if="cantRefresh($options.name) && computedCheckedRows.length"
          >
            <img src="@/assets/arrows_rotate.png"
          /></b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2 pl-2 pr-2"
            title="insertion xls"
            @click="handleUploadToExcel"
            v-if="cantUpload($options.name)"
          >
            <font-awesome-icon icon="file-import"
          /></b-button>
          <b-button
            size="sm"
            class="button-export-style ml-2 pl-2 pr-2"
            title="Télecharger xls"
            @click="exportToExcel"
            v-if="cantExport($options.name)"
          >
            <font-awesome-icon icon="cloud-download-alt"
          /></b-button>
          <export-excel-facture
            @exportFactureXls="exportExcelFacture"
            :resetModalExportFacture="computedResetModalExportFacture"
            v-if="cantExport($options.name)"
          />
          <export-excel-facture-pdf
            @exportFacturePdf="exportFacturePdf"
            v-if="cantExport($options.name) && computedgetLotsToSend.length"
          />
          <div
            v-if="getMailLoadingVertigo"
            class="loading loader-mail-size  mt-1 mr-2 ml-2"
          >
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
          <MailComponent
            v-if="
              computedgetLotsToSend.length &&
                !getMailLoadingVertigo &&
                cantSend($options.name)
            "
            :lotsToSend="computedgetLotsToSend"
            :clientToSend="computedAcheuteurToSend"
          />
          <!--  -->
          <!-- <b-button
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Zip</b-button
          > -->
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <div>
          <b-table
            ref="table"
            show-empty
            id="my-table"
            :class="
              computedTotalProjectSelected > 0
                ? 'custom-table-style table-appel-paiement-obligee-th-with-summ table-header-regie'
                : 'custom-table-style table-appel-paiement-obligee-th table-header-regie'
            "
            :items="getAppeliPaiementThTableObligee"
            :fields="computedFields"
            bordered
            sticky-header
            hover
            responsive
            head-variant="light"
            :tbody-tr-class="rowClass"
            empty-text="Il n'y a aucun enregistrement à afficher"
          >
            <template v-slot:head()="data">
              <div>
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="plus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == false
                  "
                  @click="setVisibilityFieldsHeader(data.field)"
                />
                <font-awesome-icon
                  class="icon-plus align-icon"
                  icon="minus-square"
                  v-if="
                    computedHeaderVisibility(data.field.key) &&
                      data.field.details == true
                  "
                  @click="setVisibilityFieldsHeader(data.field)"
                />
              </div>
              <div v-if="data.field.key == 'check_all'">
                <b-form-checkbox
                  v-model="checkAll"
                  @change="checkAllFunction"
                  class="check-th"
                  :value="true"
                  :unchecked-value="false"
                >
                </b-form-checkbox>
              </div>

              <span v-if="data.field.coef || data.field.statue">
                {{ data.field.label }}
                <span v-if="data.field.coef">
                  <column-header-with-tool-tip
                    v-if="
                      cantUpdate($options.name) && computedCheckedRows.length
                    "
                    :rowDetails="false"
                    :label="data.field.label"
                    :column="data.field.key"
                    :dataToUse="getAppeliPaiementThTableObligee"
                    :updateFunction="updateCoefObligee"
                    @reloadData="handleFilter"
                /></span>

                <span v-if="data.field.statue">
                  <validation-statue-obligee
                    v-if="
                      cantUpdate($options.name) &&
                        computedCheckedRowsLots.length
                    "
                    :dataToUse="getAppeliPaiementThTableObligee"
                    @reloadData="handleFilter"
                    :type="data.field.key"/></span
              ></span>

              <span v-if="!data.field.statue && !data.field.coef">
                {{ data.field.label }}</span
              >
            </template>
            <template v-slot:cell(check_all)="data">
              <b-form-checkbox
                v-model="data.item.check"
                @change="checkAllVerif(data.item)"
                class="check-th"
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(name)="data">
              {{ data.item.name }}
            </template>
            <template v-slot:cell(commission_pu)=""> - </template>
            <template v-slot:cell(commission)=""> - </template>
            <template v-slot:cell(kwh_cumac)=""> - </template>
            <template v-slot:cell(m_cumac)=""> - </template>
            <template v-slot:cell(commission_ttc)=""> - </template>
            <template v-slot:cell(commission_tva)=""> - </template>
            <template v-slot:cell(commission_statut)=""> - </template>
            <template v-slot:cell(prime_pu)=""> - </template>
            <template v-slot:cell(prime)=""> - </template>
            <template v-slot:cell(prime_ttc)=""> - </template>
            <template v-slot:cell(prime_tva)=""> - </template>
            <template v-slot:cell(prime_statut)=""> - </template>
            <template v-slot:cell(surprime_pu)=""> - </template>
            <template v-slot:cell(surprime)=""> - </template>
            <template v-slot:cell(surprime_ttc)=""> - </template>
            <template v-slot:cell(surprime_tva)=""> - </template>
            <template v-slot:cell(surprime_statut)=""> - </template>
            <template v-slot:cell(missing_prime)=""> - </template>
            <template v-slot:cell(missing_prime_ttc)=""> - </template>
            <template v-slot:cell(missing_prime_tva)=""> - </template>
            <template v-slot:cell(missing_prime_statut)=""> - </template>
            <template v-slot:cell(total)=""> - </template>
            <template v-slot:cell(avoir)=""> - </template>
            <template v-slot:cell(missing_prime_av_statut)=""> - </template>
            <template v-slot:cell(nom)=""> - </template>
            <template v-slot:cell(organisme)=""> - </template>
            <template v-slot:cell(reste_a_charge)=""> - </template>
            <template v-slot:cell(montant_ttc_avoir)=""> - </template>
            <template v-slot:cell(montant_total_ttc)=""> - </template>
            <template v-slot:cell(montant_prime_cee)=""> - </template>
            <template v-slot:cell(coef_prime_installateur)=""> - </template>
            <template v-slot:cell(missing_prime_pu)=""> - </template>
            <template v-slot:cell(missing_num_facture)=""> - </template>
            <template v-slot:cell(missing_prime_av_num_facture)="">
              -
            </template>
            <template v-slot:cell(prime_num_facture)=""> - </template>
            <template v-slot:cell(surprime_num_facture)=""> - </template>
            <template v-slot:cell(commission_num_facture)=""> - </template>

            <template v-slot:cell(details)="data">
              <button class="btn btn-anomalie" @click="data.toggleDetails">
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  v-if="data.detailsShowing == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  v-if="data.detailsShowing == true"
                />
              </button>
            </template>
            <template #row-details="data">
              <span
                v-for="(item, index) in data.item.lots"
                :key="data.item.name + index"
              >
                <template
                  ><span
                    class="align-td-item border-botom background-week"
                    :class="{ ligneAnomalieObligee: item.error == true }"
                  >
                    <td class="item-details-style col-check-obligee">
                      <b-form-checkbox
                        v-model="item.check"
                        @change="checkAllVerifDetails(data.item, item)"
                        class="check-th-details ml-2"
                        :value="true"
                        :unchecked-value="false"
                      >
                      </b-form-checkbox>
                    </td>
                    <td class="item-details-style col-plus-obligee">
                      <button
                        class="btn btn-anomalie"
                        @click="item.showDetails = !item.showDetails"
                        :disabled="item.projects.length > 0 ? false : true"
                      >
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-right"
                          v-if="item.showDetails == false"
                        />
                        <font-awesome-icon
                          class="icon-plus-double"
                          icon="angle-down"
                          v-if="item.showDetails == true"
                        />
                      </button>
                    </td>
                    <td class="item-details-style num-lot-td">
                      <span class="d-flex"
                        ><font-awesome-icon
                          :id="'tooltip-target-lot' + item.name"
                          icon="info"
                          class="info-icon-commercial-lot w-25"
                          @click.prevent.stop="
                            $refs['tooltip-target-lot' + item.name].show()
                          "
                        />
                        <!-- <mail-component :lot="item.name" /> -->
                        {{ item.name }}
                      </span>

                      <b-tooltip
                        :target="'tooltip-target-lot' + item.name"
                        triggers="hover"
                      >
                        <div>
                          <h5>Vendeur :</h5>
                          {{ item.name_vendeur }}
                        </div>
                        <div>
                          <h5>Groupe :</h5>
                          {{ item.name_group }}
                        </div>
                        <div>
                          <h5>Client :</h5>
                          {{ item.name_acheteur }}
                        </div>
                      </b-tooltip>
                    </td>
                    <td class="item-details-style">-</td>
                    <td class="item-details-style">-</td>
                    <td class="item-details-style">
                      {{ item.reste_a_charge ? item.reste_a_charge : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.ttc_avoir ? item.ttc_avoir : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{
                        item.montant_total_ttc ? item.montant_total_ttc : '-'
                      }}
                    </td>
                    <td class="item-details-style">
                      {{ item.cee ? item.cee : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.kwh ? item.kwh : '-' }}
                    </td>
                    <td class="item-details-style">
                      {{ item.mega ? item.mega : '-' }}
                    </td>
                    <td class="item-details-style">-</td>
                    <!-- prime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                    >
                      <div class="center-th-montant">
                        <div>
                          <span>{{ item.prime_ht }} €</span>
                        </div>
                        <template-obligee
                          v-if="
                            !item.error_vendeur &&
                              !item.error_acheteur &&
                              !item.error_group
                          "
                          :interfaceName="$options.name"
                          :facture_depot="true"
                          type="prime"
                          :item="item"
                          :updateFunction="
                            uploadObligeeTemplateInServieVisionFiliale
                          "
                          :showUpdateButton="
                            !item.error && item.prime_statut != 'paye'
                          "
                          :getTemplateFunction="
                            getObligeeTemplateAndFiledsDataToUpdate
                          "
                          :download="item.prime_id"
                          :value="item.prime_ht"
                        />
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('prime')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">{{ item.prime_ttc }} €</td>
                      <td class="item-details-style">{{ item.prime_tva }} €</td>
                      <td class="item-details-style">
                        <EditableInput
                          :editable="
                            computedCantUpdate && item.prime_id != null
                          "
                          champName="prime_statut"
                          editableType="select"
                          :optionsSelect="optionsSelectStatut"
                          :item="item"
                          :value="item.prime_statut"
                          :updateFunction="updateSuiviPaiementStatutTh"
                          formate="regieFacture"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ item.prime_num_facture }}
                      </td></template
                    >

                    <!-- Missing prime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                    >
                      <div class="center-th-montant">
                        <div>
                          <span :class="{ 'red-color': item.warning }"
                            >{{ item.missing_prime_ht }} €</span
                          >
                        </div>
                        <div>
                          <template-obligee
                            v-if="
                              !item.error_vendeur &&
                                !item.error_acheteur &&
                                !item.error_group
                            "
                            :interfaceName="$options.name"
                            :showUpdateButton="
                              !item.error && item.missing_prime_statut != 'paye'
                            "
                            :facture_depot="true"
                            type="missing_prime"
                            :item="item"
                            :updateFunction="
                              uploadObligeeTemplateInServieVisionFiliale
                            "
                            :getTemplateFunction="
                              getObligeeTemplateAndFiledsDataToUpdate
                            "
                            :download="item.missing_prime_id"
                            :value="item.missing_prime_ht"
                          />
                        </div>
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('missing_prime')">
                      <td class="item-details-style">-</td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': item.warning }">
                          {{ item.missing_prime_ttc }} €</span
                        >
                      </td>
                      <td class="item-details-style">
                        <span :class="{ 'red-color': item.warning }">
                          {{ item.missing_prime_tva }} €</span
                        >
                      </td>
                      <td class="item-details-style">
                        <EditableInput
                          :editable="
                            computedCantUpdate && item.missing_prime_id != null
                          "
                          champName="missing_prime_statut"
                          editableType="select"
                          :optionsSelect="optionsSelectStatut"
                          :item="item"
                          :value="item.missing_prime_statut"
                          :updateFunction="updateSuiviPaiementStatutTh"
                          formate="regieFacture"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ item.missing_prime_num_facture }}
                      </td></template
                    >

                    <!-- avoir -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                    >
                      <div class="center-th-montant">
                        <div>
                          <span>{{ item.missing_prime_av_ht }} €</span>
                        </div>
                        <div>
                          <template v-if="item.error_avoir == true">
                            <template-obligee
                              v-if="
                                !item.error_vendeur &&
                                  !item.error_acheteur &&
                                  !item.error_group
                              "
                              :facture_depot="true"
                              type="avoir"
                              :item="item"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :showUpdateButton="
                                !item.error &&
                                  item.missing_prime_av_statut != 'paye'
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :download="item.missing_prime_av_id"
                              :interfaceName="$options.name"
                              :value="'0.00'"
                            />
                          </template>
                          <template v-else>
                            <template-obligee
                              v-if="
                                !item.error_vendeur &&
                                  !item.error_acheteur &&
                                  !item.error_group
                              "
                              :facture_depot="true"
                              type="avoir"
                              :item="item"
                              :updateFunction="
                                uploadObligeeTemplateInServieVisionFiliale
                              "
                              :showUpdateButton="
                                !item.error &&
                                  item.missing_prime_av_statut != 'paye'
                              "
                              :getTemplateFunction="
                                getObligeeTemplateAndFiledsDataToUpdate
                              "
                              :download="item.missing_prime_av_id"
                              :interfaceName="$options.name"
                              :value="item.missing_prime_av_ht"
                            />
                          </template>
                        </div>
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('avoir')">
                      <td class="item-details-style">
                        <EditableInput
                          :editable="
                            computedCantUpdate &&
                              item.missing_prime_av_id != null
                          "
                          champName="missing_prime_av_statut"
                          editableType="select"
                          :optionsSelect="optionsSelectStatut"
                          :item="item"
                          :value="item.missing_prime_av_statut"
                          :updateFunction="updateSuiviPaiementStatutTh"
                          formate="regieFacture"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ item.missing_prime_av_num_facture }}
                      </td></template
                    >
                    <!-- commision -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                    >
                      <div class="center-th-montant">
                        <div>
                          <span>{{ item.commission_ht }} €</span>
                        </div>
                        <div>
                          <template-obligee
                            v-if="
                              !item.error_vendeur &&
                                !item.error_acheteur &&
                                !item.error_group
                            "
                            :interfaceName="$options.name"
                            :facture_depot="true"
                            type="commission"
                            :item="item"
                            :updateFunction="
                              uploadObligeeTemplateInServieVisionFiliale
                            "
                            :getTemplateFunction="
                              getObligeeTemplateAndFiledsDataToUpdate
                            "
                            :showUpdateButton="
                              !item.error && item.commission_statut != 'paye'
                            "
                            :download="item.commission_id"
                            :value="item.commission_ht"
                          />
                        </div>
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('commission')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.commission_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_tva }} €
                      </td>
                      <td class="item-details-style">
                        <EditableInput
                          :editable="
                            computedCantUpdate && item.commission_id != null
                          "
                          champName="commission_statut"
                          editableType="select"
                          :optionsSelect="optionsSelectStatut"
                          :item="item"
                          :value="item.commission_statut"
                          :updateFunction="updateSuiviPaiementStatutTh"
                          formate="regieFacture"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ item.commission_num_facture }}
                      </td></template
                    >

                    <!-- Surprime -->
                    <td
                      class="
                        item-details-style
                        justify-content-sb
                        th-montant-obligee
                      "
                    >
                      <div class="center-th-montant">
                        <div>
                          <span>{{ item.surprime_ht }} €</span>
                        </div>
                        <div>
                          <template-obligee
                            v-if="
                              !item.error_vendeur &&
                                !item.error_acheteur &&
                                !item.error_group
                            "
                            :interfaceName="$options.name"
                            :showUpdateButton="
                              !item.error && item.surprime_statut != 'paye'
                            "
                            :facture_depot="true"
                            type="surprime"
                            :item="item"
                            :updateFunction="
                              uploadObligeeTemplateInServieVisionFiliale
                            "
                            :getTemplateFunction="
                              getObligeeTemplateAndFiledsDataToUpdate
                            "
                            :download="item.surprime_id"
                            :value="item.surprime_ht"
                          />
                        </div>
                      </div>
                    </td>
                    <template v-if="computedShowSubRow('surprime')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">
                        {{ item.surprime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ item.surprime_tva }} €
                      </td>
                      <td class="item-details-style">
                        <EditableInput
                          :editable="
                            computedCantUpdate && item.surprime_id != null
                          "
                          champName="surprime_statut"
                          editableType="select"
                          :optionsSelect="optionsSelectStatut"
                          :item="item"
                          :value="item.surprime_statut"
                          :updateFunction="updateSuiviPaiementStatutTh"
                          formate="regieFacture"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ item.surprime_num_facture }}
                      </td></template
                    >
                    <td class="item-details-style td-total-coef-oblige">
                      <!-- {{ computedTotalDepot(item) }} € -->
                      -
                    </td></span
                  ></template
                >
                <template v-if="item.showDetails == true">
                  <span
                    v-for="(itemD, index) in item.projects"
                    :key="'depot' + itemD + index"
                    class="align-td-item border-botom background-depot"
                    :class="{ ligneAnomalieObligee: itemD.error == true }"
                  >
                    <td class="item-details-style col-check-obligee">
                      {{ itemD.error }}
                      <b-form-checkbox
                        v-model="itemD.check"
                        @change="
                          checkAllVerifDetailsDepot(
                            data.item,
                            item,
                            itemD,
                            index
                          )
                        "
                        class="check-th-details ml-2"
                        :value="true"
                        :unchecked-value="false"
                      >
                      </b-form-checkbox>
                    </td>
                    <td class="item-details-style col-plus-obligee">
                      <span
                        ><font-awesome-icon icon="info" class="icon-info-show"
                      /></span>
                    </td>
                    <td class="item-details-style num-lot-td">
                      <font-awesome-icon
                        icon="lock-open"
                        class="mr-1"
                        v-if="itemD.montant_min_lock == false"
                      />
                      <font-awesome-icon
                        icon="lock"
                        class="mr-1"
                        v-if="itemD.montant_min_lock == true"
                      />
                      {{ itemD.numero_dossier }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.nom1 }} {{ itemD.prenom1 }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.organisme }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.reste_a_charge }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.montant_ttc_avoir }}
                    </td>
                    <td class="item-details-style">
                      {{ itemD.montant_total_ttc }} €
                    </td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="computedCantUpdate"
                        champName="montant_prime_cee"
                        :item="itemD"
                        :value="itemD.montant_prime_cee"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="montant_prime_cee"
                        :showIconEdit="true"
                        :data="item"
                      />
                    </td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="computedCantUpdate"
                        champName="kwh_cumac"
                        :item="itemD"
                        :value="itemD.kwh_cumac"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="kwh_cumac"
                        :showIconEdit="true"
                        :data="item"
                      />
                    </td>
                    <td class="item-details-style">
                      {{ itemD.m_cumac }}
                    </td>
                    <td class="item-details-style">
                      <EditableInputSuiviTh
                        :editable="computedCantUpdate"
                        champName="coef_prime_installateur"
                        :item="itemD"
                        :value="itemD.coef_prime_installateur"
                        type="text"
                        :updateFunction="updateSuiviPaiementDataTh"
                        valueToShow="coef_prime_installateur"
                        :showIconEdit="true"
                        :data="item"
                      />
                    </td>
                    <!-- prime  -->
                    <td class="item-details-style th-montant-obligee">
                      {{ itemD.prime_ht }}
                    </td>
                    <template v-if="computedShowSubRow('prime')">
                      <td class="item-details-style">
                        <template v-if="itemD.reste_a_charge > 1">
                          <EditableInputSuiviTh
                            :editable="computedCantUpdate"
                            champName="prime_pu"
                            :item="itemD"
                            :value="itemD.prime_pu"
                            type="text"
                            :updateFunction="updateSuiviPaiementDataTh"
                            valueToShow="prime_pu"
                            :showIconEdit="true"
                            :data="item"
                          />
                        </template>
                        <template v-else>
                          {{ itemD.prime_pu }}
                        </template>
                      </td>
                      <td class="item-details-style">
                        {{ itemD.prime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.prime_tva }} €
                      </td>
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">-</td></template
                    >

                    <!-- missing prime  -->
                    <td class="item-details-style th-montant-obligee">
                      <span :class="{ 'red-color': itemD.warning }">
                        {{ itemD.missing_prime_ht }} €</span
                      >
                    </td>
                    <template v-if="computedShowSubRow('missing_prime')">
                      <td class="item-details-style">
                        {{ itemD.missing_prime_pu }}
                      </td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': itemD.warning }">
                          {{ itemD.missing_prime_ttc }} €</span
                        >
                      </td>

                      <td class="item-details-style">
                        <span :class="{ 'red-color': itemD.warning }">
                          {{ itemD.missing_prime_tva }} €</span
                        >
                      </td>
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">-</td></template
                    >
                    <!-- avoir -->
                    <td class="item-details-style th-montant-obligee">
                      {{ itemD.montant_avoir }}
                    </td>
                    <template v-if="computedShowSubRow('avoir')">
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">-</td></template
                    >

                    <!-- commision -->
                    <td class="item-details-style th-montant-obligee">
                      {{ itemD.commission_ht }} €
                    </td>
                    <template v-if="computedShowSubRow('commission')">
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate &&
                              item.commission_statut != 'paye'
                          "
                          champName="commission_pu"
                          :item="itemD"
                          :value="itemD.commission_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="commission_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.commission_tva }} €
                      </td>
                      <td class="item-details-style b">-</td>
                      <td class="item-details-style">-</td></template
                    >
                    <!-- surprime -->
                    <td class="item-details-style th-montant-obligee">
                      {{ itemD.surprime_ht }} €
                    </td>
                    <template v-if="computedShowSubRow('surprime')">
                      <td class="item-details-style">
                        <EditableInputSuiviTh
                          :editable="
                            computedCantUpdate && item.surprime_statut != 'paye'
                          "
                          champName="surprime_pu"
                          :item="itemD"
                          :value="itemD.surprime_pu"
                          type="text"
                          :updateFunction="updateSuiviPaiementDataTh"
                          valueToShow="surprime_pu"
                          :showIconEdit="true"
                          :data="item"
                        />
                      </td>
                      <td class="item-details-style">
                        {{ itemD.surprime_ttc }} €
                      </td>
                      <td class="item-details-style">
                        {{ itemD.surprime_tva }} €
                      </td>
                      <td class="item-details-style">-</td>
                      <td class="item-details-style">-</td></template
                    >
                    <td class="item-details-style td-total-coef-oblige">
                      <!-- {{ computedTotal(itemD) }} € -->
                      {{ itemD.total_coef }}
                    </td>
                  </span>
                </template>
              </span>
            </template>
          </b-table>
          <div v-if="computedTotalProjectSelected > 0">
            <b-table-simple
              class="table-fiche custom-table-style mb-0"
              responsive
            >
              <b-tbody>
                <b-tr class="title-ligne ">
                  <b-th class="newcolor">NB dossier</b-th>
                  <b-th class="newcolor">Reste à charge</b-th>
                  <b-th class="newcolor">Montant TTC de l'avoir</b-th>
                  <b-th class="newcolor">Montant Total TTC</b-th>
                  <b-th class="newcolor">Montant Prime CEE </b-th>
                  <b-th class="newcolor">Kwh cumac </b-th>
                  <b-th class="newcolor">Mega cumac </b-th>
                  <b-th class="newcolor background-td-prim"
                    >Prime Installateur
                  </b-th>
                  <b-th class="newcolor background-td-prim"
                    >Prime Installateur TTC
                  </b-th>
                  <b-th class="newcolor background-td-comm"
                    >Comm Installateur
                  </b-th>
                  <b-th class="newcolor background-td-comm"
                    >Comm Installateur TTC
                  </b-th>
                  <b-th class="newcolor background-td-comm"
                    >Comm Installateur TVA
                  </b-th>
                  <b-th class="newcolor background-td-commission"
                    >Commission
                  </b-th>
                  <b-th class="newcolor background-td-commission"
                    >Commission TTC</b-th
                  >
                  <b-th class="newcolor background-td-commission"
                    >Commission TVA</b-th
                  >
                  <b-th class="newcolor background-td-suprim"
                    >Surprime HT
                  </b-th>
                  <b-th class="newcolor background-td-suprim"
                    >Surprime TTC</b-th
                  >
                  <b-th class="newcolor background-td-suprim"
                    >Surprime TVA</b-th
                  >
                </b-tr>
                <b-tr>
                  <b-td class="">{{ computedTotalProjectSelected }}</b-td>
                  <b-td>{{ computedRestAcharge }}€</b-td>
                  <b-td>{{ computedMontantTtcAvoir }}€</b-td>
                  <b-td>{{ computedMontantTotalTtc }}€</b-td>
                  <b-td>{{ computedMontantPrimeCee }}€</b-td>
                  <b-td>{{ computedMontantkwh }}€</b-td>
                  <b-td>{{ computedMontantMegaCumac }}€</b-td>
                  <b-td>{{ computedMontantPrimeInstallateur }}€</b-td>
                  <b-td>{{ computedMontantPrimeInstallateurTTC }}€</b-td>
                  <b-td>{{ computedMontantCommInstallateur }}€</b-td>
                  <b-td>{{ computedMontantCommInstallateurTTC }}€</b-td>
                  <b-td>{{ computedMontantCommInstallateurTVA }}€</b-td>
                  <b-td>{{ computedMontantCommission }}€</b-td>
                  <b-td>{{ computedMontantCommissionTTC }}€</b-td>
                  <b-td>{{ computedMontantCommissionTVA }}€</b-td>
                  <b-td>{{ computedMontantSurprime }}€</b-td>
                  <b-td>{{ computedMontantSurprimeTTC }}€</b-td>
                  <b-td>{{ computedMontantSurprimeTVA }}€</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-if="this.getAppeliPaiementThTableObligee" class="footer-style">
            <b-pagination
              v-model="page"
              :total-rows="geTtotalRowsThObligee"
              :per-page="per_page"
              aria-controls="my-table"
              pills
              align="center"
              size="sm"
              @change="pagination"
              class="pagination-style"
            ></b-pagination>
            <div class="per-page-element-style">
              <div class="box-label-champ">
                <div class="label-box-style">
                  <span class="title-tabel">Eléments par page</span>
                </div>
              </div>
              <b-form-select
                v-model="per_page"
                :options="perPageList"
                @change="changePerPage"
                class="b-form-select-new-style bg-select"
              ></b-form-select>
            </div>
          </div>
        </div>
        <!-- <div id="chart">
          <apexchart
            type="line"
            height="100%"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div> -->
      </div>
    </div>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadPaiementObligeeTemplateModal"
      id="uploadPaiementObligeeTemplateModal"
      size="sm"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-size-upload"
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadPaiementObligeeTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <!-- <div
          v-if="getChargementAppelPaiementThObligeeFacture != true"
          class="chargement-message-calender"
        >
          Chargement en cours...
        </div> -->
        <!-- <div
          v-if="getErrorAppeliPaiementThObligeeFacture"
          class="error-message"
        >
          <div class="error">
            {{ getErrorAppeliPaiementThObligeeFacture }}
          </div>
        </div> -->
      </div>

      <div class="appel-oblige-template-block-style" id="hmt_block_id">
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <div class="AppeluploadFile" @change="change">
              <div class="box-white">
                <div class="box-title-uplaod mt-4">
                  <span class="text">Importer</span>
                </div>
                <b-row align-h="center">
                  <div class="container" @drop="handleFileDrop">
                    <div class="file-wrapper">
                      <input
                        type="file"
                        name="file-input"
                        id="uploadInput"
                        @change="handleFileInput"
                        accept=".csv, application/vnd.ms-excel,.xlsx"
                      />
                      <div class="upload_label">
                        <img
                          src="@/assets/Icon feather-upload.png"
                          class="icon-upload"
                        />
                        <p class="drag-title mb-0">Drag & Drop to insert</p>
                        <p class="drag-title mt-0 mb-0">or</p>
                        <div class="btn-upload-file">Browse File</div>
                      </div>
                      <div class="upload_label">
                        <p class=" drag-title" v-if="this.file">
                          {{ this.file.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-row>
                <div class="file-exel-style"><span>*</span> Fichier Excel</div>
              </div>
            </div>

            <div class="message text-aligne-center">
              <div v-if="loadingUpload" class="chargement-message-calender">
                Chargement en cours...
              </div>
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="missingOrganisme"
      id="missingOrganisme"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Dossier sans organisme'"
      @hidden="hideModal('missingOrganisme')"
      modal-class="modal-extaction-bdd-frais"
    >
      <ul>
        <li
          v-for="item in missingOrganismeInObligeeFile"
          :key="'org' + item"
          class="text-missing-organisme-obligee"
        >
          {{ item }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import TemplateObligee from './component/templateObligee.vue';

// import VueApexCharts from 'vue-apexcharts';
// import MailComponent from './MailComponent.vue';

export default {
  name: 'Gestions-des-appels-à-paiement-Obligé',

  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      file: null,
      optionsSelectStatut: [{ full_name: 'a paye' }, { full_name: 'paye' }],
      date_debut: null,
      date_fin: null,
      page: 1,
      per_page: 10,
      filterFiliale: [],
      filterLot: [],
      filterOrganisme: [],

      fields: [
        {
          key: 'check_all',
          label: '',
          views: true,
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          for: 'all'
        },
        {
          key: 'details',
          label: '',
          views: true,
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          for: 'all'
        },

        {
          key: 'name',
          label: 'Numéro de lot',
          for: 'all',
          views: true,
          thClass: 'num-lot-th',
          tdClass: 'num-lot-td'
        },
        { key: 'nom', label: 'Nom et prénom', for: 'all', views: true },
        { key: 'organisme', label: 'Organisme', for: 'all', views: true },
        {
          key: 'reste_a_charge',
          label: 'Reste à charge',
          for: 'all',
          views: true
        },
        {
          key: 'montant_ttc_avoir',
          label: "Montant TTC de l'avoir",
          for: 'all',
          views: true
        },
        {
          key: 'montant_total_ttc',
          label: 'Montant Total TTC',
          for: 'all',
          views: true
        },
        {
          key: 'montant_prime_cee',
          label: 'Montant Prime CEE',
          for: 'all',
          views: true
        },
        {
          key: 'kwh_cumac',
          label: 'Kwh cumac',
          for: 'all',
          views: true
        },

        {
          key: 'm_cumac',
          label: 'Mega cumac',
          for: 'all',
          views: true
        },
        {
          key: 'coef_prime_installateur',
          label: 'Coef prime installateur',
          for: 'all',
          views: true,
          coef: true
        },
        {
          key: 'prime',
          label: 'Prime Installateur',
          for: 'prime',
          views: true,
          details: false,
          thClass: 'prime-bg th-montant-obligee',
          tdClass: 'col-montant-obligee'
        },
        {
          key: 'prime_pu',
          label: 'Coef prime',
          for: 'prime',
          views: false,
          thClass: 'prime-bg'
        },
        {
          key: 'prime_ttc',
          label: 'Mt  TTC',
          for: 'prime',
          views: false,
          thClass: 'prime-bg'
        },
        {
          key: 'prime_tva',
          label: 'Mt  TVA',
          for: 'prime',
          views: false,
          thClass: 'prime-bg'
        },
        {
          key: 'prime_statut',
          label: 'Statut',
          for: 'prime',
          views: false,
          thClass: 'prime-bg',
          statue: true
        },
        {
          key: 'prime_num_facture',
          label: 'Numéro de facture',
          for: 'prime',
          views: false,
          thClass: 'prime-bg'
        },

        {
          key: 'missing_prime',
          label: 'Comm installateur',
          for: 'missing_prime',
          views: true,
          thClass: 'missing-bg th-montant-obligee',
          details: false,
          tdClass: 'col-montant-obligee'
        },
        {
          key: 'missing_prime_pu',
          label: 'Coef Comm',
          for: 'missing_prime',
          views: false,
          thClass: 'missing-bg'
        },
        {
          key: 'missing_prime_ttc',
          label: 'Mt TTC',
          for: 'missing_prime',
          views: false,

          thClass: 'missing-bg'
        },

        {
          key: 'missing_prime_tva',
          label: 'Mt TVA',
          for: 'missing_prime',
          views: false,
          thClass: 'missing-bg'
        },
        {
          key: 'missing_prime_statut',
          label: 'Statut',
          for: 'missing_prime',
          views: false,
          thClass: 'missing-bg',
          statue: true
        },
        {
          key: 'missing_prime_num_facture',
          label: 'Numéro de facture',
          for: 'missing_prime',
          views: false,
          thClass: 'missing-bg'
        },

        {
          key: 'avoir',
          label: 'Montant avoir',
          thClass: 'avoir-bg th-montant-obligee',
          details: false,
          views: true,
          tdClass: 'col-montant-obligee'
        },
        {
          key: 'missing_prime_av_statut',
          label: 'Statut',
          thClass: 'avoir-bg',
          views: false,
          statue: true
        },
        {
          key: 'missing_prime_av_num_facture',
          label: 'Numéro de facture',
          thClass: 'avoir-bg',
          views: false
        },
        {
          key: 'commission',
          label: 'Commission',
          for: 'commmision',
          views: true,
          details: false,
          thClass: 'commission-bg th-montant-obligee',
          tdClass: 'col-montant-obligee'
        },
        {
          key: 'commission_pu',
          label: 'Coef commission',
          coef: true,
          for: 'commmision',
          views: false,
          thClass: 'commission-bg'
        },
        {
          key: 'commission_ttc',
          label: 'MT TTC',
          for: 'commmision',
          views: false,
          thClass: 'commission-bg'
        },

        {
          key: 'commission_tva',
          label: 'Mt TVA',
          for: 'commmision',
          views: false,
          thClass: 'commission-bg'
        },
        {
          key: 'commission_statut',
          label: 'Statut',
          for: 'commmision',
          views: false,
          thClass: 'commission-bg',
          statue: true
        },
        {
          key: 'commission_num_facture',
          label: 'Numéro de facture',
          for: 'commmision',
          views: false,
          thClass: 'commission-bg'
        },

        {
          key: 'surprime',
          label: 'Surprime ht',
          for: 'surprime',
          views: true,
          thClass: 'surprime-bg th-montant-obligee',
          details: false,
          tdClass: 'col-montant-obligee'
        },
        {
          key: 'surprime_pu',
          label: 'Surprime  Coef',
          coef: true,
          for: 'surprime',
          views: false,
          thClass: 'surprime-bg'
        },
        {
          key: 'surprime_ttc',
          label: 'Mt TTC',
          for: 'surprime',
          views: false,
          thClass: 'surprime-bg'
        },

        {
          key: 'surprime_tva',
          label: 'Mt TVA',
          for: 'surprime',
          views: false,
          thClass: 'surprime-bg'
        },
        {
          key: 'surprime_statut',
          label: 'Statut',
          for: 'surprime',
          views: false,
          thClass: 'surprime-bg',
          statue: true
        },
        {
          key: 'surprime_num_facture',
          label: 'Numéro de facture',
          for: 'surprime',
          views: false,
          thClass: 'surprime-bg'
        },

        {
          key: 'total',
          label: 'Total Coef',
          for: 'all',
          views: true,
          thClass: 'th-total-coef-oblige',
          tdClass: 'td-total-coef-oblige'
        }
      ],
      checkAll: false,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      resetModalExportFacture: false
    };
  },
  methods: {
    ...mapActions([
      'getAppelPaiementObligeeThFiltred',
      'downloadFactureObligeeItem',
      'downloadFactureGlobaleObligeeItem',
      'updateSuiviPaiementDataTh',
      'getObligeeTemplateAndFiledsDataToUpdate',
      'uploadObligeeTemplateInServieVisionFiliale',
      'refreshAooelPaiementObligeeAction',
      'updateCoefObligee',
      'getAllDataForFilterObligee',
      'updateSuiviPaiementStatutTh',
      'downloadPaiementObligeeThFiltredExcel',
      'sendMailVertigo',
      'uploadObligeexls',
      'uploadObligeexlsAfterConfirmation',
      'downloadPaiementObligeeThFactureFiltredExcel',
      'downloadPaiementObligeeThFactureFiltredPdf'
    ]),
    setVisibilityFieldsHeader(data) {
      this.fields.map(item => {
        if (item.key == data.key) {
          item.details = !item.details;
        }
        if (item.for == data.for && item.key != data.key) {
          item.views = !item.views;
        }
      });
    },
    disabledStartDate(date) {
      return this.date_fin && date >= new Date(this.date_fin);
    },
    disabledEndDate(date) {
      return this.date_debut && date < new Date(this.date_debut);
    },
    rowClass(item, type) {
      return 'ligneNormale';
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterObligee({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      this.filterLot = [];
      this.filterFiliale = [];
      this.filterOrganisme = [];
      this.handleFilter();
    },
    handleFilter() {
      this.getAppelPaiementObligeeThFiltred({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        page: this.page,
        per_page: this.per_page
      });
      this.setLocalStorageAppeloblige();
    },
    downloadFactureGlobale(item, type) {
      this.downloadFactureGlobaleObligeeItem({ item: item, type: type });
    },
    downloadFactureItem(item, type, id) {
      this.downloadFactureObligeeItem({
        id: id
      });
    },
    async refreshAooelPaiementObligee() {
      let tab = [];
      this.getAppeliPaiementThTableObligee.map(row =>
        row.lots.map(l => {
          if (l.projects.some(p => p.check == true)) {
            tab.push(l.name);
          }
        })
      );
      const response = await this.refreshAooelPaiementObligeeAction({
        lot: tab
      });
      if (response == true) {
        this.handleFilter();
      }
    },
    setLocalStorageAppeloblige() {
      localStorage.setItem(
        'appelOblige',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          organisme: this.filterOrganisme,
          lot: this.filterLot,
          filiale: this.filterFiliale
        })
      );
    },
    checkAllFunction() {
      this.getAppeliPaiementThTableObligee.map(item => {
        item.check = this.checkAll;
        item.lots.map(itemD => {
          itemD.check = this.checkAll;
          itemD.projects.map(itemP => {
            itemP.check = this.checkAll;
          });
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.lots.map(itemD => {
        itemD.check = item.check;
        itemD.projects.map(itemP => {
          itemP.check = item.check;
        });
      });
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(itemP => {
        itemP.check = item.check;
      });
    },
    checkAllVerifDetailsDepot(data, item, itemD, index) {
      if (itemD.check == false && data.check == true) {
        data.check = false;
      }
      if (itemD.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (itemD.check == false && item.checkAll == true) {
        item.checkAll = false;
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    exportToExcel() {
      this.downloadPaiementObligeeThFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        exportExcel: true,
        dataCheck: this.computedgetLotsToSend
      });
    },
    async exportExcelFacture(selectedModel) {
      const response = await this.downloadPaiementObligeeThFactureFiltredExcel({
        lot: this.filterLot,
        organisme: this.filterOrganisme,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        filiale: this.filterFiliale,
        VH: selectedModel,
        dataCheck: this.computedgetLotsToSend
      });
      if (response) {
        this.resetModalExportFacture = !this.resetModalExportFacture;
      }
    },
    async exportFacturePdf() {
      await this.downloadPaiementObligeeThFactureFiltredPdf({
        dataCheck: this.computedCheckedRows
      });
    },
    handleUploadToExcel() {
      this.$refs['uploadPaiementObligeeTemplateModal'].show();
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.file = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async change() {
      this.loadingUpload = true;
      if (this.file !== null) {
        const payload = {
          file: this.file

          // depot: this.depot
        };
        const response = await this.uploadObligeexls(payload);
        if (response.succes) {
          this.loadingUpload = false;
          if (response.data && response.data.data) {
            if (response.data.data.length) {
              let extra =
                "<div style='margin: auto 18%;height: 260px;overflow-x: auto'> <h4><i>Dossier existe :</i></h4>";

              for (const [key, value] of Object.entries(response.data.data)) {
                extra =
                  extra +
                  '<li style="    font-size: 12px;float: left;width: 100%;">' +
                  value +
                  '</li>';
              }
              extra = extra + '</div>';
              this.$swal
                .fire({
                  background: 'rgb(245 245 252)',
                  title:
                    'Êtes-vous sur de vouloir écraser les dossiers dupliquer (Le montant min sera recalculé)',
                  type: 'warning',
                  icon: 'warning',
                  locale: 'fr',
                  allowOutsideClick: false,
                  showCloseButton: true,
                  showConfirmButton: true,
                  showCancelButton: true,
                  html: extra,
                  confirmButtonText: 'Oui',
                  cancelButtonText: 'Non',
                  confirmButtonClass: 'btn btn-success',
                  cancelButtonClass: 'btn btn-danger',
                  cancelButtonColor: '#d33',
                  customClass: {
                    actions: 'my-actions',
                    cancelButton: 'order-2 ',
                    confirmButton: 'order-1'
                  }
                })
                .then(async result => {
                  let force = '';
                  if (result.isDismissed && result.dismiss != 'close')
                    force = 'no';
                  if (result.isConfirmed) force = 'oui';
                  if (this.file !== null && force != '') {
                    const payload = {
                      file: this.file,
                      force: force
                      // depot: this.depot
                    };
                    this.loadingUpload = true;
                    const response = await this.uploadObligeexlsAfterConfirmation(
                      payload
                    );
                    this.loadingUpload = false;
                    this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                    this.handleFilter();
                  }
                });
            } else {
              if (this.file !== null) {
                const payload = {
                  file: this.file,
                  force: 'non'
                  // depot: this.depot
                };
                this.loadingUpload = true;
                const response = await this.uploadObligeexlsAfterConfirmation(
                  payload
                );
                this.loadingUpload = false;
                this.$refs['uploadPaiementObligeeTemplateModal'].hide();
                this.handleFilter();
              }
            }
          }
        } else {
          this.loadingUpload = false;
          if (
            this.geterrorObligeXls &&
            (this.geterrorObligeXls.error || this.geterrorObligeXls.missing)
          ) {
            let htmlContent = '';
            let error = '';
            if (this.geterrorObligeXls.error) {
              let error = this.geterrorObligeXls.error;
              htmlContent = error;
            }
            let missing = this.geterrorObligeXls.missing
              ? '<div><h4><i>Colonnes manquantes :</i></h4>'
              : '';
            if (this.geterrorObligeXls.missing) {
              for (const [key, value] of Object.entries(
                this.geterrorObligeXls.missing
              )) {
                missing = missing + '<li>' + value + '</li>';
              }
              missing = missing + '</div>';
              htmlContent =
                "<div style='margin: auto;height: 260px;overflow-x: auto;' class='error-upload-scroll'>" +
                missing +
                '</div>';
            }

            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'error',
              html: htmlContent,
              allowOutsideClick: false,
              showCloseButton: true
            });
          }
        }
      }
    },
    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    }
  },
  computed: {
    ...mapGetters([
      'missingOrganismeInObligeeFile',
      'getChargementAppelPaiementThObligee',
      'getErrorAppeliPaiementThObligee',
      'getErrorAppeliPaiementUpdateThObligee',
      'getAppeliPaiementThTableObligee',
      'getTotalAppelPaiementObligee',
      'getListOrganismeObligee',
      'cantExport',
      'cantUpload',
      'cantUpdate',
      'cantRefresh',
      'cantSend',
      'geTtotalRowsThObligee',
      'getListInstallateurbligee',
      'getListLotsOligee',
      'getLoadingDataFilterObligeeThProjects',
      'getMailLoadingVertigo',
      'geterrorObligeXls'
    ]),
    computedResetModalExportFacture() {
      return this.resetModalExportFacture;
    },
    computedgetLotsToSend() {
      let lotsToSend = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          if (item2.check === true) {
            lotsToSend.push(item2.name);
          }
        })
      );
      return lotsToSend;
    },
    computedShowSubRow() {
      return function(data) {
        return this.fields.filter(item => item.key == data)[0].details;
      };
    },
    computedFields() {
      return this.fields.filter(item => item.views == true);
    },
    computedHeaderVisibility() {
      return function(data) {
        if (
          data == 'prime' ||
          data == 'surprime' ||
          data == 'missing_prime' ||
          data == 'avoir' ||
          data == 'commission'
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    computedCantUpdate() {
      return this.cantUpdate(this.$options.name);
    },
    computedTotalDepot() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission_pu.replace(' ', '')) +
            parseFloat(data.prime_pu.replace(' ', '')) +
            parseFloat(data.surprime_pu.replace(' ', '')) +
            parseFloat(data.missing_prime_pu.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedTotal() {
      return function(data) {
        if (data) {
          let x =
            parseFloat(data.commission.replace(' ', '')) +
            parseFloat(data.prime.replace(' ', '')) +
            parseFloat(data.surprime.replace(' ', '')) +
            parseFloat(data.missing_prime.replace(' ', ''));
          let res = x.toFixed(2);
          return res;
        }
        return '00.00';
      };
    },
    computedGetAppeliPaiementThTableObligeeCategories() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(item.name);
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_commission() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.commission_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.prime_pu));
        });
      }

      return table;
    },
    computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.missing_prime_pu));
        });
      }

      return table;
    },

    computedGetAppeliPaiementThTableObligeeTotal_pu_surprime() {
      let table = [];
      if (
        this.getAppeliPaiementThTableObligee &&
        this.getAppeliPaiementThTableObligee.length
      ) {
        this.getAppeliPaiementThTableObligee.map(item => {
          table.push(parseFloat(item.surprime_pu));
        });
      }

      return table;
    },
    chartOptions() {
      return {
        chart: {
          height: '100%',
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4]
        },
        title: {
          text: '',
          align: 'left',
          offsetX: 170
        },
        xaxis: {
          categories: this.computedGetAppeliPaiementThTableObligeeCategories
        },
        yaxis: [
          {
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#2a2a2a'
              }
            },
            // title: {
            //   text: 'Income (thousand crores)',
            //   style: {
            //     color: '#008FFB'
            //   }
            // },
            tooltip: {
              enabled: true
            }
          }
          // {
          //   seriesName: 'Income',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#00E396'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#00E396'
          //     }
          //   },
          //   title: {
          //     text: 'Operating Cashflow (thousand crores)',
          //     style: {
          //       color: '#00E396'
          //     }
          //   }
          // }
          // {
          //   seriesName: 'Revenue',
          //   opposite: true,
          //   axisTicks: {
          //     show: true
          //   },
          //   axisBorder: {
          //     show: true,
          //     color: '#FEB019'
          //   },
          //   labels: {
          //     style: {
          //       colors: '#FEB019'
          //     }
          //   },
          //   title: {
          //     text: 'Revenue (thousand crores)',
          //     style: {
          //       color: '#FEB019'
          //     }
          //   }
          // }
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          }
        }
      };
    },
    series() {
      return [
        {
          name: 'Montant commission',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_commission,
          color: '#FF4560'
        },
        {
          name: 'Montant prime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_prime,
          color: '#4d4bac'
        },
        {
          name: 'Montant surprime',
          type: 'column',
          data: this.computedGetAppeliPaiementThTableObligeeTotal_pu_surprime
        },
        {
          name: 'Montant extrat',
          type: 'column',
          data: this
            .computedGetAppeliPaiementThTableObligeeTotal_pu_missing_prime,
          color: '#1ae9f3'
        }
      ];
    },
    computedDownloadGlobal() {
      return function(data) {
        for (let i = 0; i < data.item.length; i++) {
          if (data.item[i][data.column] == null) {
            return false;
          }
        }
        return true;
      };
    },
    computedCheckedRows() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.projects.some(p => p.check == true));
      });
      return result1;
    },
    computedCheckedRowsLots() {
      let result1 = this.getAppeliPaiementThTableObligee.filter(row => {
        return row.lots.some(l => l.check == true);
      });
      return result1;
    },
    computedAcheuteurToSend() {
      let listClient = [];
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            if (listClient.indexOf(item2.name_acheteur) === -1) {
              listClient.push(item2.name_acheteur);
            }
          }
        })
      );
      return listClient;
    },
    computedTotalProjectSelected() {
      let total = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                total++;
              }
            });
          }
        })
      );
      return total;
    },
    computedRestAcharge() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.reste_a_charge) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTtcAvoir() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_ttc_avoir) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantTotalTtc() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_total_ttc) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeCee() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.montant_prime_cee) * 100) / 100 ||
                  0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantkwh() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.kwh_cumac) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantMegaCumac() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.m_cumac.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantPrimeInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateur() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommInstallateurTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (
                item3.check === true &&
                parseFloat(
                  item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                ) > 0
              ) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.missing_prime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommission() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.commission_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantCommissionTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(parseFloat(item3.commission_tva) * 100) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprime() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ht.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTTC() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_ttc.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    },
    computedMontantSurprimeTVA() {
      let somme = 0;
      this.computedCheckedRows.map(item1 =>
        item1.lots.map(item2 => {
          {
            item2.projects.map(item3 => {
              if (item3.check === true) {
                somme +=
                  Math.round(
                    parseFloat(
                      item3.surprime_tva.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) / 100 || 0;
              }
            });
          }
        })
      );
      return somme;
    }
  },
  components: {
    // apexchart: VueApexCharts,
    EditableInputSuiviTh: () =>
      import('@/views/component/EditableInputSuiviTh'),
    EditableInput: () => import('../../component/EditableInput.vue'),
    TemplateObligee,
    columnHeaderWithToolTip: () =>
      import('./component/columnHeaderWithToolTip.vue'),
    ValidationStatueObligee: () =>
      import('./component/ValidationStatueObligee.vue'),
    MailComponent: () => import('./component/MailComponent.vue'),
    ExportExcelFacture: () => import('./component/exportExcelFacture'),
    ExportExcelFacturePdf: () => import('./component/exportExcelFacturePdf')
  },
  async mounted() {
    this.date_debut = moment()
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD');
    this.getAllDataForFilterObligee({
      date_debut: this.date_debut,
      date_fin: this.date_fin
    });

    if (localStorage.getItem('appelOblige')) {
      this.date_debut = JSON.parse(
        localStorage.getItem('appelOblige')
      ).date_debut;
      this.date_fin = JSON.parse(localStorage.getItem('appelOblige')).date_fin;
      this.filterLot = JSON.parse(localStorage.getItem('appelOblige')).lot;
      this.filterOrganisme = JSON.parse(
        localStorage.getItem('appelOblige')
      ).organisme;
      this.filterFiliale = JSON.parse(
        localStorage.getItem('appelOblige')
      ).filiale;
    } else {
      this.setLocalStorageAppeloblige();
    }
    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.gestion-appel-paiement-obligee {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #4d4bac;
  }
}
.border-botom {
  border-bottom: 1px solid #f6f5fb;
}
.table-appel-paiement-obligee-th {
  max-height: calc(100vh - 230px) !important;
  height: calc(100vh - 230px) !important;
  margin-bottom: 0px;
  overflow-x: scroll;
}
.table-appel-paiement-obligee-th-with-summ {
  max-height: calc(100vh - 326px) !important;
  height: calc(100vh - 326px) !important;
  margin-bottom: 0px;
  overflow-x: scroll;
}
.background-td-prim {
  background-color: #fef2cb;
}
.background-td-comm {
  background-color: #dadada;
}
.background-td-commission {
  background-color: #fbe4d5;
}
.background-td-suprim {
  background-color: #b4c6e7;
}
.sticky-column-body {
  position: absolute;
  background-color: #f6f5fb;
  margin-left: 0px;
}
.sticky-column-head {
  position: absolute;
  background-color: #f0f1ff;
  margin-left: 0px;
}
</style>
<style lang="scss">
.gestion-appel-paiement-obligee {
  .table-rapport-style {
    display: block;
    #chart {
      position: relative;
      // max-width: 40%;
      // width: 38%;
      width: 100%;
    }
  }
  .justify-content-sb {
    // display: flex !important;
    line-height: 11px !important;
    padding: 0px 8px !important;
    justify-content: space-between !important;
  }
  .button-export-style {
    padding-left: 1.5px;
    padding-right: 1.7px;
    img {
      width: 24px;
    }
  }
}
.table-appel-paiement-obligee-th-with-summ {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    // justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb !important;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #b0afd9 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;

      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}
.table-appel-paiement-obligee-th {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 10px;
    span {
      cursor: pointer;
      display: table-row;
      vertical-align: inherit;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    vertical-align: middle;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .col-plus-obligee,
    .num-lot-td,
    .col-check-obligee,
    .td-total-coef-oblige {
      background-color: #e0e0eb !important;
      z-index: 6;
    }
    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff;
      display: table-cell;
    }
  }
  .align-td-item {
    padding: 0px !important;
    .item-details-style {
      justify-content: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    display: table-row;
    vertical-align: middle;

    td {
      width: 100px;
      min-width: 100px;
      padding: 4px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .col-plus-obligee,
    .col-check-obligee,
    .num-lot-td,
    .td-total-coef-oblige {
      background-color: #b0afd9 !important;
      min-height: 19px;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
      display: table-cell;
      border: 1px solid #fff;

      margin: 0;
    }
  }
  .center-th-montant {
    display: flex !important;
    position: relative;
  }
}

.info-icon-commercial-lot {
  font-size: 11.5px;
  margin-right: 4px;
  color: #e09f24;
  &:hover {
    color: #f5a206;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.time-icon-style {
  margin-top: 33px;
  color: red;
}

// .icon-plus-double {
//   font-size: 13px;
//   margin-right: 4px;
//   color: #e09f24;
//   &:hover {
//     color: #f5a206;
//   }
// }
.loader-mail-size {
  width: 24px;
  height: 24px;
}
</style>
<style lang="scss">
.customSelectMultipleFilterLot {
  width: 160px !important;
}

.error_oblige_update {
  position: fixed;
  bottom: 45px;
  right: 45px;
  z-index: 99;
  font-size: 15px;
  width: 350px;
  .alert-danger {
    padding: 20px;
  }
}
.table-appel-paiement-obligee-th {
  .oblige-validation-frais {
    position: absolute;
    right: 0;
  }

  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
}
.table-appel-paiement-obligee-th-with-summ {
  .oblige-validation-frais {
    position: absolute;
    right: 0;
  }

  td,
  th {
    border-left: 1px solid white;
    border-right: 0px solid !important;
  }
  .col-plus-obligee {
    z-index: 7;
    min-width: 42px !important;
    width: 42px !important;
  }
  .col-check-obligee {
    min-width: 42px !important;
    width: 43px !important;
    z-index: 9;
  }
  .th-check-obligee {
    z-index: 10 !important;
  }
  .th-plus-obligee {
    z-index: 11 !important;
    width: 43px !important;
    min-width: 43px !important;
  }
  .num-lot-th {
    z-index: 7 !important;
  }
}
.AppeluploadFile {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  border-radius: 19px;
  height: calc(100% - 74px);
  justify-content: center;
  align-items: center;
  display: flex;
  .footer-upload {
    width: 100%;
    bottom: 0px;
    position: absolute;
    z-index: 0;
  }
  .box-white {
    z-index: 50;
    width: fit-content;
    padding: 20px 20px 25px 0px;
    background: #f5f4fa;
    border-radius: 17px;
    width: 90%;
    top: -5%;
    position: relative;
    text-align: initial;
    .box-title-uplaod {
      position: relative;
      background-color: #ffffff;
      width: 215px;
      padding: 7px 23px;
      border-radius: 0px 18px 18px 0px;
      text-align: end;
      margin-bottom: 26px;
      .text {
        font-size: 15px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: #5d5d5d;
      }
    }
  }
  .container {
    height: 187px;
    width: 380px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px dashed #4d4bac;
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 40px;
        position: relative;
        top: 32px;
        .drag-title {
          font-size: 15px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #4d4bac;
        }
        p {
          font-size: 15px;
        }
        .icon-upload {
          width: 33px;
          height: 33px;
          margin-bottom: 15px;
        }
        .btn-upload-file {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 400;
          width: 60%;
          border-radius: 29px;
          background-color: #4d4bac;
          padding: 11px 20px;
          border: 0;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
  .file-exel-style {
    font-size: 8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #5d5d5d;
    position: relative;
    right: 23%;
    text-align: end;
    margin-top: 10px;
    span {
      font-weight: 500;
      color: #fe808b;
    }
  }
  .error-upload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    text-align: center;
    bottom: -2%;
    right: 3%;
    z-index: 50;
    color: red;
    .title-error {
      font-weight: 600;
    }
  }
}
.custom-modal-size-upload .modal-dialog {
  max-width: 47%;
  top: 0% !important;
  height: 100%;
  margin: auto !important;
}

.error-upload-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.error-upload-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.error-upload-scroll::-webkit-scrollbar-thumb {
  background: #b5b5e6;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.house-danger {
  padding: 3px 7px !important;
  background-color: #e4261b;
  &:hover,
  &:focus {
    background-color: #e4261b;
  }
}
.text-missing-organisme-obligee {
  font-size: 10px;
  font-weight: 700;
}
</style>
