<template>
  <div class="oblige-validation-frais">
    <div class="mr-1">
      <font-awesome-icon
        v-if="cantExport(interfaceName) && download"
        icon="arrow-alt-circle-down"
        class="icon-plus"
        @click.prevent="downloadFactureItem()"
      />
    </div>
    <div>
      <font-awesome-icon
        v-if="showUpdateButton && cantCreate(interfaceName)"
        icon="file-code"
        @click.prevent="handleEditTemplate"
        class="icon-style-color"
        :class="value == '0.00' ? 'disabled' : ''"
        :disabled="value == '0.00'"
      />
    </div>
    <div>
      <font-awesome-icon
        icon="check-circle"
        class="icon-style-check-facture"
        v-if="download"
      />
    </div>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateObligeeToUpload && getTemplateObligeeToUpload.name
          ? getTemplateObligeeToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg"
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getChargementAppelPaiementThObligeeFacture == true"
          class="loading-custom-template-block"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loeder"
          />
        </div>
        <div
          v-if="getErrorAppeliPaiementThObligeeFacture"
          class="error-message"
        >
          <div class="error">
            {{ getErrorAppeliPaiementThObligeeFacture }}
          </div>
        </div>
      </div>

      <div
        class="oblige-template-block-style"
        v-if="getTemplateObligeeToUpload"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              @click="handleUpload"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div
              v-if="loadingUpload == true"
              class="loading-custom-template-block"
            >
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
            <div class="message text-aligne-center">
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <b-form-group label="Date de Facture" class="input-modal-champ">
              <date-picker
                value-type="format"
                format="DD-MM-YYYY"
                type="date"
                :value="getTemplateObligeeToUpload.date"
                class="inputDateTemplate"
                @input="
                  changeFieldsValue({
                    key: 'date_id',
                    value: getTemplateObligeeToUpload.date,
                    e: $event,
                    champName: 'date'
                  })
                "
                @focus="
                  focusInFields({
                    key: 'date_id'
                  })
                "
              ></date-picker>
            </b-form-group>
            <div>
              <b-form-group
                label="HT"
                label-for="title-template-ht"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-ht"
                  v-model="getTemplateObligeeToUpload.ht"
                  @input="
                    changeFieldsValue({
                      key: 'ht_id',
                      value: getTemplateObligeeToUpload.ht
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'ht_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group
              label="TVA "
              label-for="title-template-num-fact"
              class="input-modal-champ"
            >
              <b-form-input
                id="title-template-num-fact"
                v-model="getTemplateObligeeToUpload.tva"
                required
                @input="
                  changeFieldsValue({
                    key: 'tva_id',
                    value: getTemplateObligeeToUpload.tva
                  })
                "
                @focus="
                  focusInFields({
                    key: 'tva_id'
                  })
                "
                disabled
              ></b-form-input>
            </b-form-group>
            <div>
              <b-form-group
                label="TTC"
                label-for="title-template-banque"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-banque"
                  v-model="getTemplateObligeeToUpload.ttc"
                  @input="
                    changeFieldsValue({
                      key: 'ttc_id',
                      value: getTemplateObligeeToUpload.ttc
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'ttc_id'
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Banque"
                label-for="title-template-banque"
                class="input-modal-champ"
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-banque"
                  v-model="getTemplateObligeeToUpload.bank"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <!-- Rib -->
            <div>
              <!-- Rib  -->
              <b-form-group
                label="Rib"
                label-for="rib-template-select"
                v-if="getTemplateObligeeToUpload"
              >
                <multiselect
                  v-model="getTemplateObligeeToUpload.rib"
                  :options="computedRibs"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class=" multiselect-vue-custom-style-cdg"
                  :showLabels="false"
                >
                </multiselect>
              </b-form-group>
            </div>
            <!-- <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="getTemplateObligeeToUpload.description"
                  @input="
                    changeFieldsValue({
                      key: 'description_id',
                      value: getTemplateObligeeToUpload.description
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'description_id'
                    })
                  "
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div> -->
            <!-- <div>
              <b-form-group
                label="IBAN "
                label-for="title-template-iban"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-iban"
                  v-model="getTemplateObligeeToUpload.iban"
                  @input="
                    changeFieldsValue({
                      key: 'iban_id',
                      value: getTemplateObligeeToUpload.iban
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'iban_id'
                    })
                  "
                ></b-form-input>
              </b-form-group>
            </div>

            <div>
              <b-form-group
                label="BIC "
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-swift"
                  v-model="getTemplateObligeeToUpload.swift"
                  @input="
                    changeFieldsValue({
                      key: 'swift_id',
                      value: getTemplateObligeeToUpload.swift
                    })
                  "
                  @focus="
                    focusInFields({
                      key: 'swift_id'
                    })
                  "
                ></b-form-input>
              </b-form-group>
            </div> -->
          </div>
        </div>
        <div
          id="hmt_template_block_id"
          class="template-style"
          v-if="getTemplateObligeeToUpload"
          v-html="getTemplateObligeeToUpload.template"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    facture_depot: { default: false },
    item: { required: true },
    type: { required: true },
    updateFunction: { required: true },
    getTemplateFunction: { required: true },
    download: { required: true },
    showUpdateButton: { default: true },
    interfaceName: { require: true },
    value: { require: true }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null
    };
  },
  methods: {
    ...mapActions([
      'setDaraTemplateObligeUploded',
      'downloadFactureObligeeItem'
    ]),
    async handleEditTemplate() {
      if (this.value != '0.00') {
        this.$refs['uploadFactureTemplateModal'].show();

        const response = await this.getTemplateFunction({
          lot: this.item.name,
          type: this.type == 'avoir' ? 'missing_prime' : this.type,
          av: this.type == 'avoir' ? 'oui' : 'no',
          vendeur:
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group,
          acheteur: this.item.id_acheteur,
          item: this.item
        });
        if (response) {
          let now = new Date();
          let dateFormate = moment(now).format('DD-MM-YYYY');
          if (
            this.getTemplateObligeeToUpload.date == null ||
            this.getTemplateObligeeToUpload.date == ''
          ) {
            document.getElementById('date_id').innerHTML = dateFormate;
            this.getTemplateObligeeToUpload.date = dateFormate;
          }
          if (
            this.getTemplateObligeeToUpload &&
            this.getTemplateObligeeToUpload.rib != null
          ) {
            this.getTemplateObligeeToUpload.rib = this.computedRibs.find(
              item => item.rib == this.getTemplateObligeeToUpload.rib
            );
          }

          this.getTemplateObligeeToUpload.bank = this.getTemplateObligeeToUpload.banque_societe;
        }
      }
    },
    async handleUpload() {
      if (
        this.getTemplateObligeeToUpload &&
        this.getTemplateObligeeToUpload.rib != null
      ) {
        this.loadingUpload = true;
        this.errorUpload = null;
        const response = await this.updateFunction({
          lot: this.item.name,
          type: this.type == 'avoir' ? 'missing_prime' : this.type,
          av: this.type == 'avoir' ? 'oui' : 'no',
          vendeur:
            this.type == 'prime' ||
            this.type == 'avoir' ||
            this.type == 'missing_prime'
              ? this.item.id_vendeur
              : this.item.id_group,
          acheteur: this.item.id_acheteur,
          ht: this.getTemplateObligeeToUpload.ht,
          tva: this.getTemplateObligeeToUpload.tva,
          ttc: this.getTemplateObligeeToUpload.ttc,
          swift: this.getTemplateObligeeToUpload.rib.swift,
          iban: this.getTemplateObligeeToUpload.rib.iban,
          item: this.item,
          date: this.getTemplateObligeeToUpload.date,
          bank: this.download
            ? this.getTemplateObligeeToUpload.bank
            : this.getTemplateObligeeToUpload.banque_societe
        });

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      } else {
        this.errorUpload =
          'Une erreur est servenue ! IBAN et SWIFT obligatoire';
      }
    },
    changeFieldsValue(item) {
      if (item.key == 'tva_id') {
        if (
          this.getTemplateObligeeToUpload.tva != null &&
          this.getTemplateObligeeToUpload.tva != ''
        ) {
          let tvaCoef = Number.parseFloat(
            (this.getTemplateObligeeToUpload.tva + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          let value = Number.parseFloat(
            (document.getElementById('total_ht_id').innerText + '')
              .replace(' ', '')
              .replace(',', '.')
          );

          document.getElementById('total_tva_id').innerHTML = (
            (value / 100) *
            tvaCoef
          ).toFixed(2);
          var elms = document.querySelectorAll('[id=total_ttc_id]');
          for (var i = 0; i < elms.length; i++) {
            elms[i].innerHTML = ((value / 100) * tvaCoef + value).toFixed(2);
          }
        }
      }
      this[item.champName] = item.e;
      if (item && item.e) {
        this.getTemplateObligeeToUpload[item.champName] = item.e;
        document.getElementById(item.key).innerHTML = item.e;
      } else {
        document.getElementById(item.key).innerHTML = item.value;
      }
    },
    focusInFields(item) {
      var myElement = document.getElementById(item.key);
      var rect = myElement.getBoundingClientRect();
      document.getElementById('hmt_template_block_id').scrollTop =
        rect.top + window.pageYOffset;
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.setDaraTemplateObligeUploded();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    downloadFactureItem() {
      this.downloadFactureObligeeItem({
        id: this.download
      });
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateObligeeToUpload',
      'getChargementAppelPaiementThObligeeFacture',
      'getErrorAppeliPaiementThObligeeFacture',
      'getFraisVisionFilialeData',
      'cantExport',
      'cantCreate'
    ]),
    computedRibs() {
      return this.type == 'prime' ||
        this.type == 'avoir' ||
        this.type == 'missing_prime'
        ? this.getTemplateObligeeToUpload?.vendeur_ribs.map(item => {
            return { rib: item.rib, iban: item.iban, swift: item.swift };
          })
        : this.getTemplateObligeeToUpload?.group_ribs.map(item => {
            return { rib: item.rib, iban: item.iban, swift: item.swift };
          });
    }
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.oblige-validation-frais {
  display: flex;
}
.oblige-template-block-style {
  display: flex;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #4d4bac;
  }
}

.icon-style-color:disabled,
.icon-style-color[disabled] {
  background-color: #cccccc;
  color: #b1b1b1;
}
</style>
<style lang="scss">
.oblige-validation-frais {
  .template-style::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .template-style::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d0cddc;
    border-radius: 10px;
  }
  .template-style::-webkit-scrollbar-thumb {
    background: #dadae4;
    border-radius: 7px;
  }
  .fields-input {
    label {
      font-size: 13px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
      text-align: start;
      color: #2a2a2a;
      margin-left: 1%;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    #title-template-description {
      border-radius: 15px;
    }
  }
}
</style>
